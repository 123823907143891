export enum TemplateType {
  Warrenty = "Warrenty",
}

export enum FieldType {
  Label = "label",
  Other = "other",
  Date = "date",
  Unit = "unit",
}
