export const AppTheme = {
  "color-primary-100": "#C5CFF7",
  "color-primary-200": "#8FA0EF",
  "color-primary-300": "#5367CF",
  "color-primary-400": "#2839A0",
  "color-primary-500": "#000D62",
  "color-primary-600": "#000A54",
  "color-primary-700": "#000746",
  "color-primary-800": "#000538",
  "color-primary-900": "#00032F",
  "color-primary-transparent-100": "rgba(0, 13, 98, 0.08)",
  "color-primary-transparent-200": "rgba(0, 13, 98, 0.16)",
  "color-primary-transparent-300": "rgba(0, 13, 98, 0.24)",
  "color-primary-transparent-400": "rgba(0, 13, 98, 0.32)",
  "color-primary-transparent-500": "rgba(0, 13, 98, 0.4)",
  "color-primary-transparent-600": "rgba(0, 13, 98, 0.48)",
  "color-success-100": "#E6F8CE",
  "color-success-200": "#C9F19F",
  "color-success-300": "#9AD769",
  "color-success-400": "#6AAF3F",
  "color-success-500": "#337A12",
  "color-success-600": "#24680D",
  "color-success-700": "#175709",
  "color-success-800": "#0D4605",
  "color-success-900": "#063A03",
  "color-success-transparent-100": "rgba(51, 122, 18, 0.08)",
  "color-success-transparent-200": "rgba(51, 122, 18, 0.16)",
  "color-success-transparent-300": "rgba(51, 122, 18, 0.24)",
  "color-success-transparent-400": "rgba(51, 122, 18, 0.32)",
  "color-success-transparent-500": "rgba(51, 122, 18, 0.4)",
  "color-success-transparent-600": "rgba(51, 122, 18, 0.48)",
  "color-info-100": "#C7F9E9",
  "color-info-200": "#92F3DD",
  "color-info-300": "#58DDCA",
  "color-info-400": "#2EBBB3",
  "color-info-500": "#008A8E",
  "color-info-600": "#006C7A",
  "color-info-700": "#005166",
  "color-info-800": "#003B52",
  "color-info-900": "#002B44",
  "color-info-transparent-100": "rgba(0, 138, 142, 0.08)",
  "color-info-transparent-200": "rgba(0, 138, 142, 0.16)",
  "color-info-transparent-300": "rgba(0, 138, 142, 0.24)",
  "color-info-transparent-400": "rgba(0, 138, 142, 0.32)",
  "color-info-transparent-500": "rgba(0, 138, 142, 0.4)",
  "color-info-transparent-600": "rgba(0, 138, 142, 0.48)",
  "color-warning-100": "#FBF0C9",
  "color-warning-200": "#F8DE95",
  "color-warning-300": "#ECC15E",
  "color-warning-400": "#D9A136",
  "color-warning-500": "#C17700",
  "color-warning-600": "#A55F00",
  "color-warning-700": "#8A4A00",
  "color-warning-800": "#6F3700",
  "color-warning-900": "#5C2A00",
  "color-warning-transparent-100": "rgba(193, 119, 0, 0.08)",
  "color-warning-transparent-200": "rgba(193, 119, 0, 0.16)",
  "color-warning-transparent-300": "rgba(193, 119, 0, 0.24)",
  "color-warning-transparent-400": "rgba(193, 119, 0, 0.32)",
  "color-warning-transparent-500": "rgba(193, 119, 0, 0.4)",
  "color-warning-transparent-600": "rgba(193, 119, 0, 0.48)",
  "color-danger-100": "#F9D3D0",
  "color-danger-200": "#F4A3A5",
  "color-danger-300": "#DF6F7D",
  "color-danger-400": "#C04762",
  "color-danger-500": "#FF0000",
  "color-danger-600": "#811240",
  "color-danger-700": "#6C0C3D",
  "color-danger-800": "#570738",
  "color-danger-900": "#480434",
  "color-danger-transparent-100": "rgba(150, 25, 65, 0.08)",
  "color-danger-transparent-200": "rgba(150, 25, 65, 0.16)",
  "color-danger-transparent-300": "rgba(150, 25, 65, 0.24)",
  "color-danger-transparent-400": "rgba(150, 25, 65, 0.32)",
  "color-danger-transparent-500": "rgba(150, 25, 65, 0.4)",
  "color-danger-transparent-600": "rgba(150, 25, 65, 0.48)",
  "color-primary": "#1C1C1D",
  "color-primary2": "#252525",
  "color-secondary": "#FFFFFF",
  "color-secondary-dark": "#005da6",
  "color-primary-dark": "#000766",
  "color-dark": "#898989",
  "color-dark-light": "#f2f2f2",
  "color-grey": "#0000009E",
  "color-grey2": "#A0A0A0",
  "color-grey3": "#C4C4C4",
  "color-grey4": "#3F3F3F",
  "color-grey5": "#2E2E2E",
  "color-black": "#000236",
  "color-danger": "#ED2359",
  "color-secondary-font": "#151215",
  "color-primary-shade": "#272727",
  "color-accent1": "#0089FF",
};
